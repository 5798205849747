import http from "@/utils/axios";
import common from "@/common";
const api = {
  query: (rangeFlag, data) =>
    http.get(
      `tenant-user/baseData/${common.getApiCata()}/${rangeFlag}/query`,
      data
    ),
  add: (rangeFlag, data) =>
    http.post(
      `tenant-user/baseData/${common.getApiCata()}/${rangeFlag}/add`,
      data
    ),
  modify: (rangeFlag, data) =>
    http.put(
      `tenant-user/baseData/${common.getApiCata()}/${rangeFlag}/modify`,
      data
    ),
  move: (rangeFlag, data) =>
    http.put(
      `tenant-user/baseData/${common.getApiCata()}/${rangeFlag}/move`,
      data
    ),
  delete: (rangeFlag, data) =>
    http.delete(
      `tenant-user/baseData/${common.getApiCata()}/${rangeFlag}/delete`,
      data
    ),
};
export default api;
