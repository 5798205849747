<template>
  <div class="h100">
    <common-layout>
      <template #header>
        <el-form inline>
          <el-form-item label="名称">
            <el-input
              placeholder="请输入"
              v-model="form.name"
              clearable
            ></el-input>
          </el-form-item>
          <el-form-item label="编码">
            <el-input
              placeholder="请输入"
              v-model="form.code"
              clearable
            ></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="query">查询</el-button>
            <el-button type="primary" plain @click="handleAdd()"
              >新增</el-button
            >
          </el-form-item>
          <el-form-item>
            <el-tag type="info" size="mini"
              ><i class="el-icon-info"></i>拖动以排序</el-tag
            >
          </el-form-item>
        </el-form>
      </template>
      <template #default>
        <el-table
          :data="tableData"
          height="100%"
          ref="table"
          row-key="id"
          :expand-row-keys="expandedDataIds"
          border
          :row-class-name="flagTrClass"
          :tree-props="{ children: 'children', hasChildren: 'hasChildren' }"
          v-loading="tableLoading"
        >
          <el-table-column
            prop="searchName"
            label="名称"
            fixed="left"
            min-width="220"
          >
            <template slot-scope="scope">
              <span v-html="scope.row.searchName"></span>
            </template>
          </el-table-column>
          <el-table-column prop="searchCode" label="编码" min-width="180">
            <template slot-scope="scope">
              <span v-html="scope.row.searchCode"></span>
            </template>
          </el-table-column>
          <el-table-column prop="icon" label="图标" width="120">
            <template slot-scope="scope">
              <el-avatar
                v-if="scope.row.icon && scope.row.icon.startsWith('http')"
                shape="square"
                :src="scope.row.icon"
              ></el-avatar>
              <span v-else>{{ scope.row.icon }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="type" label="类别" width="120">
            <template slot-scope="scope">
              <span v-if="scope.row.type == 'system'">系统</span>
              <span v-if="scope.row.type == 'menu'">菜单</span>
              <span v-if="scope.row.type == 'resource'">资源</span>
              <span v-if="scope.row.type == 'scheme_menus'">方案菜单</span>
              <span v-if="scope.row.type == 'function'">按钮</span>
            </template>
          </el-table-column>
          <!-- <el-table-column
            prop="rangeFlag"
            label="级别"
            width="120"
            v-if="curSystem.code == 'devops'"
          >
            <template slot-scope="scope">
              <span v-if="scope.row.type == 'system'">
                <span v-if="scope.row.rangeFlag == 'global'">全局</span>
                <span v-if="scope.row.rangeFlag == 'app'">应用</span>
              </span>
              <span v-else>--</span>
            </template>
          </el-table-column> -->
          <el-table-column prop="" label="使用状态" width="120">
            <template slot-scope="scope">
              {{ scope.row.useStatus ? "已启用" : "已禁用" }}
            </template>
          </el-table-column>
          <el-table-column prop="remarks" label="备注" width="150">
          </el-table-column>

          <el-table-column
            prop="createUser"
            label="创建人"
            align="left"
            min-width="100"
          />
          <el-table-column
            prop="createTime"
            label="创建时间"
            align="left"
            min-width="150"
          />
          <el-table-column
            prop="updateUser"
            label="更新人"
            align="left"
            min-width="100"
          />
          <el-table-column
            prop="updateTime"
            label="更新时间"
            align="left"
            min-width="150"
          />

          <el-table-column label="操作" align="left" width="180" fixed="right">
            <template slot-scope="scope">
              <el-button type="text" @click="handleAdd(scope.row)">
                新增
              </el-button>
              <el-button type="text" @click="handleEdit(scope.row)">
                编辑
              </el-button>
              <el-button type="text" @click="handleUpdateStatus(scope.row)">
                {{ scope.row.useStatus ? "禁用" : "启用" }}
              </el-button>
              <el-button type="text" @click="handleDelete(scope.row)">
                删除
              </el-button>
            </template>
          </el-table-column>
        </el-table>
        <div
          id="drop-line-indicator"
          class="el-tree__drop-indicator"
          style="top: 120px; left: 42px; height: 6px; z-index: 5; display: none"
        ></div>
      </template>
    </common-layout>
    <add-modal
      :rangeFlag="rangeFlag"
      :show.sync="showDialogs"
      :detail="curRow"
      :isAdd="isAdd"
      @close="closeModal"
    />

    <el-dialog title="你真要跨级拖拽吗？" :visible.sync="dragTip" width="350px">
      <span slot="footer" class="dialog-footer">
        <el-button @click="dragDialogs = false">取 消</el-button>
        <el-button type="primary" @click="moveData(false)">不再提示</el-button>
        <el-button type="primary" @click="moveData(true)">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import addModal from "./addModal";
import api from "@/api/auth/baseData";

class DrogData {
  constructor() {
    this.dragMap = new Map(); //数据与表关系
    this.dropType = null; //释放类型 before、after、inner
    this.dragRow = null; //拖拽行
    this.dropRow = null; //拖拽释放位置的所在行
  }
  toJSON() {
    return {
      dropType: this.dropType,
      dragId: this.dragRow.id,
      dropId: this.dropRow.id,
    };
  }
}

export default {
  props: {
    rangeFlag: {
      require: true,
      type: String,
    },
  },
  components: { addModal },
  data() {
    return {
      form: {},
      tableLoading: false,
      tableData: [],
      expandedDataIds: [],
      showDialogs: false,
      isAdd: false,
      curRow: {},
      dragInfo: new DrogData(),
      continueDragTip: true,
      dragDialogs: false,
    };
  },
  computed: {
    dragTip() {
      return this.continueDragTip && this.dragDialogs;
    },
    // curSystem() {
    //   return this.$store.state.curSystem;
    // },
  },
  mounted() {
    this.query();
  },
  watch: {
    rangeFlag: function () {
      this.query();
    },
  },
  methods: {
    query() {
      this.tableLoading = true;
      api
        .query(this.rangeFlag, this.form)
        .then((res) => {
          this.tableData = res.data.baseDataTree;
          this.expandedDataIds = res.data.expandedDataIds;
          this.$nextTick(() => {
            const resetDragRowStyle = (e, isAdd) => {
              let rowClass = null;
              const trClassArr = e.currentTarget.classList;
              for (const i in trClassArr) {
                if (trClassArr[i].startsWith("table-data-")) {
                  rowClass = trClassArr[i];
                  break;
                }
              }
              this.$el.querySelectorAll("." + rowClass).forEach((row) => {
                if (isAdd) {
                  row.classList.add("is-drop-inner");
                } else {
                  row.classList.remove("is-drop-inner");
                }
              });
            };

            let trElArr = this.$el.getElementsByClassName("table-drag");
            //配置每行的拖拽
            for (let index = 0; index < trElArr.length; index++) {
              const trEl = trElArr[index];
              trEl.setAttribute("draggable", true);
              trEl.ondragstart = (e) => {
                e.currentTarget.classList.forEach((key) => {
                  if (key.startsWith("table-data-")) {
                    e.dataTransfer.setData("text/plain", key);
                  }
                });
                e.dataTransfer.effectAllowed = "move";
              };

              trEl.ondragover = (e) => {
                let key = e.dataTransfer.getData("text/plain");
                if (e.currentTarget.classList.contains(key)) {
                  return;
                }
                e.preventDefault();
                const dropIndicatorElStyle = this.$el.querySelector(
                  "#drop-line-indicator"
                ).style;
                const dropEl = e.currentTarget;
                const dropElPosition = dropEl.getBoundingClientRect();

                //水平方向线位置
                let tdCellElArr = dropEl.querySelector("td .cell").childNodes;
                if (tdCellElArr.length <= 1) {
                  dropIndicatorElStyle.left = `${dropElPosition.left}px`;
                  dropIndicatorElStyle.width = `${dropElPosition.width}px`;
                } else {
                  let treeSpaceLeft =
                    tdCellElArr[tdCellElArr.length - 2].getBoundingClientRect()
                      .left;
                  dropIndicatorElStyle.left = `${treeSpaceLeft}px`;
                  dropIndicatorElStyle.width = `${
                    dropElPosition.left + dropElPosition.width - treeSpaceLeft
                  }px`;
                }
                //竖直方向线位置
                const interval = dropElPosition.bottom - dropElPosition.top;
                const prevPoint = dropElPosition.top + interval * 0.25;
                const nextPoint = dropElPosition.top + interval * 0.75;

                const dragInfo = this.dragInfo;

                if (e.clientY < prevPoint) {
                  dragInfo.dropType = "before";
                  dropIndicatorElStyle.top = `${dropElPosition.top - 3}px`;
                  dropIndicatorElStyle.removeProperty("display");
                  // dropEl.classList.remove("is-drop-inner")
                  resetDragRowStyle(e, false);
                } else if (e.clientY > nextPoint) {
                  dragInfo.dropType = "after";
                  dropIndicatorElStyle.top = `${dropElPosition.bottom - 3}px`;
                  dropIndicatorElStyle.removeProperty("display");
                  // dropEl.classList.remove("is-drop-inner")
                  resetDragRowStyle(e, false);
                } else {
                  dragInfo.dropType = "inner";
                  dropIndicatorElStyle.display = "none";
                  // dropEl.classList.add("is-drop-inner")
                  resetDragRowStyle(e, true);
                }
              };
              trEl.ondragleave = (e) => {
                resetDragRowStyle(e, false);
                const dropIndicatorElStyle = this.$el.querySelector(
                  "#drop-line-indicator"
                ).style;
                dropIndicatorElStyle.display = "none";
              };
              trEl.ondrop = (e) => {
                const dragInfo = this.dragInfo;
                let dragKey = e.dataTransfer
                  .getData("text/plain")
                  .replace("table-data-", "");
                dragInfo.dragRow = dragInfo.dragMap.get(dragKey);
                const dropEl = e.currentTarget;
                dropEl.classList.forEach((dropKey) => {
                  if (dropKey.startsWith("table-data-")) {
                    dragInfo.dropRow = dragInfo.dragMap.get(
                      dropKey.replace("table-data-", "")
                    );
                  }
                });
                // dropEl.classList.remove("is-drop-inner")
                resetDragRowStyle(e, false);
                const dropIndicatorElStyle = this.$el.querySelector(
                  "#drop-line-indicator"
                ).style;
                dropIndicatorElStyle.display = "none";
                if (dragInfo.dragRow == dragInfo.dropRow) {
                  return;
                }
                console.log("释放行", dragInfo);
                let dropParentId =
                  dragInfo.dropType == "inner"
                    ? dragInfo.dropRow.id
                    : dragInfo.dropRow.parentId;
                if (
                  this.continueDragTip &&
                  dragInfo.dragRow.parentId != dropParentId
                ) {
                  this.dragDialogs = true;
                } else {
                  this.moveData(true);
                }
              };
            }
          });
        })
        .finally(() => {
          this.tableLoading = false;
        });
    },

    flagTrClass({ row, rowIndex }) {
      this.dragInfo.dragMap.set(row.id + "", row);
      return "table-drag table-data-" + row.id;
    },

    handleAdd(row) {
      this.curRow = row || { parentId: 0 };
      this.isAdd = true;
      this.showDialogs = true;
    },
    handleEdit(row) {
      this.curRow = _.cloneDeep(row);
      this.isAdd = false;
      this.showDialogs = true;
    },
    moveData(continueDragTip) {
      if (!continueDragTip) {
        this.continueDragTip = continueDragTip;
      }
      this.dragDialogs = false;
      api.move(this.rangeFlag, this.dragInfo).then((res) => {
        this.query();
      });
    },

    handleDelete(row) {
      this.$confirm("此操作将永久删除该数据, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        api.delete(this.rangeFlag, { id: row.id }).then(() => {
          this.$msg.success("操作成功");
          this.query();
        });
      });
    },
    closeModal() {
      this.showDialogs = false;
      this.query();
    },
    handleUpdateStatus(row) {
      this.$confirm(
        row.useStatus ? "确定禁用此数据吗" : "确定启用此数据吗",
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      ).then(() => {
        let params = {
          ...row,
          useStatus: !row.useStatus,
        };
        api.modify(this.rangeFlag, params).then(() => {
          this.$msg.success("操作成功");
          this.query();
        });
      });
    },
  },
};
</script>

<style scoped lang="scss">
/deep/ .el-table tr.is-drop-inner {
  background-color: #33bba7;
  color: #fff;
}
</style>
