<template>
  <el-dialog
    :title="isAdd ? '新增' : '编辑'"
    width="500px"
    :visible="show"
    @close="close"
    :close-on-click-modal="false"
  >
    <el-form :label-width="form.type=='resource'?'90px':'70px'" :model="form" :rules="rules" ref="form">
      <el-form-item label="类型" prop="type">
        <el-select v-model="form.type" style="width: 100%">
          <el-option
            v-for="(value, key) in type"
            :key="key"
            :value="key"
            :label="value"
          ></el-option>
        </el-select>
      </el-form-item>
      <!-- <el-form-item label="级别" prop="rangeFlag" v-if="form.type == 'system' && curSystem.code == 'devops'">
        <el-select v-model="form.rangeFlag" style="width: 100%">
          <el-option value="global" label="全局"></el-option>
          <el-option value="app" label="应用"></el-option>
        </el-select>
      </el-form-item> -->
      <el-form-item label="名称" prop="name">
        <el-input placeholder="请输入" v-model="form.name" clearable></el-input>
      </el-form-item>

      <template v-if="form.type">
        <template>
          <el-form-item label="编码" prop="code">
            <el-input
              placeholder="请输入"
              v-model="form.code"
              clearable
            ></el-input>
          </el-form-item>

          <el-form-item label="图标" v-if="form.type != 'function'">
            <el-input
              v-if="['scheme_menus','menu','resource'].includes(form.type)"
              placeholder="请输入"
              v-model="form.icon"
              clearable
            ></el-input>
            <image-upload
              v-if="form.type === 'system'"
              :url.sync="form.icon"
              @success="handleAvatarSuccess"
              :params="{ businessType: 'system_icon' }"
              :fileSize="2"
              fileType="image"
            />
          </el-form-item>
          <el-form-item label="是否为菜单页" v-if="form.type == 'resource'">
            <el-radio-group v-model="form.isMenuPage">
              <el-radio-button :label="true">是</el-radio-button>
              <el-radio-button :label="false">否</el-radio-button>
            </el-radio-group>
          </el-form-item>
        </template>

        <!-- <template v-if="form.type == 'function'">
          <el-form-item label="微服务名" prop="appName">
            <el-input
              placeholder="请输入"
              v-model="form.appName"
              clearable
            ></el-input>
          </el-form-item>
          <el-form-item label="请求方法" prop="requestMethod">
            <el-select v-model="form.requestMethod" style="width: 100%">
              <el-option
                v-for="item in requestMethods"
                :key="item"
                :value="item"
                :label="item"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="请求地址" prop="apiUri">
            <el-input
              placeholder="请输入"
              v-model="form.apiUri"
              clearable
            ></el-input>
          </el-form-item>
        </template> -->
      </template>

      <el-form-item label="备注">
        <el-input
          placeholder="请输入"
          type="textarea"
          v-model="form.remarks"
          clearable
        ></el-input>
      </el-form-item>
    </el-form>
    <div slot="footer">
      <el-button @click="close">取消</el-button>
      <el-button type="primary" @click="save">确定</el-button>
    </div>
  </el-dialog>
</template>
<script>
import api from "@/api/auth/baseData";
import imageUpload from "@/components/imageUpload";
export default {
  components: { imageUpload },
  props: {
    show: {
      default: false,
      type: Boolean,
    },
    isAdd: {
      default: false,
      type: Boolean,
    },
    detail: {
      default: null,
      type: Object,
    },
    rangeFlag: {
      require: true,
      type: String,
    },
  },
  watch: {
    show(val) {
      if (!val) {
        this.$refs["form"]?.resetFields();
        return;
      }
      if (this.detail.parentId === 0) {
        this.form = {
          parentId: 0,
          rangeFlag: this.rangeFlag,
          type: "menu",
          isMenuPage:true
        };
        return;
      }
      if (this.isAdd) {
        this.form = {
          parentId: this.detail.id,
          rangeFlag: this.rangeFlag,
          type: "menu",
          isMenuPage:true
        };
        return;
      }
      this.form = this.detail;
    },
  },
  computed: {
    curSystem() {
      return this.$store.state.curSystem;
    },
  },
  data() {
    return {
      form: {},
      type: {
        system: "系统",
        menu: "菜单",
        resource: "资源组件",
        function: "按钮",
        scheme_menus: '方案菜单'
      },
      requestMethods: ["GET", "POST", "PUT", "DELETE"],
      rules: {
        type: [{ required: true, message: "请选择类型", trigger: "blur" }],
        code: [{ required: true, message: "请输入编码", trigger: "blur" }],
        name: [{ required: true, message: "请输入名称", trigger: "blur" }],
        appName: [
          { required: true, message: "请输入微服务编码名", trigger: "blur" },
        ],
        requestMethod: [
          { required: true, message: "请选择请求方式", trigger: "blur" },
        ],
        apiUri: [
          { required: true, message: "请输入接口地址", trigger: "blur" },
        ],
        // rangeFlag: [{ required: true, message: '请选择级别', trigger: 'blur' }],
      },
    };
  },
  methods: {
    close() {
      this.$emit("update:show", false);
    },
    handleAvatarSuccess(res) {
      const { ossUrl } = res.data;
      this.$set(this.form, "icon", ossUrl);
    },
    save() {
      this.$refs["form"].validate((valid) => {
        if (!valid) return;
        let params = {
          ...this.form,
        };
        if(params.type!='resource') {
          delete params.isMenuPage
        }
        Reflect.deleteProperty(params, "children");
        let handleType = this.isAdd ? "add" : "modify";
        api[handleType](this.rangeFlag, params).then(() => {
          this.$msg.success("操作成功");
          this.$emit("close");
        });
      });
    },
  },
};
</script>
